@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');

* {
    font-family: 'Roboto', 'Arial', sans-serif;
}

#navbar {
    background-color: #B90064 !important;
    height: 50px;
}

#navbar a {
    color: white;
}

@media (min-width: 1200px) {
    #navbar-collapsed ul {
        height: 50px;
    }
}

@media (max-width: 1200px) {
    #navbar-collapsed ul {
        background-color: #002856;
    }
    #dashboard-nav li:nth-child(1) {
        border-right: none !important;
    }
}

#logoutButton {
    border-radius: 10px !important;
    background-color: #002856 !important;
    height: 38px;
}

#dashboard-nav a {
    color: #666666;
}

#dashboard-nav a:hover {
    color: #B90064 !important;
}

.pink-text {
    color: #B90064 !important;
}

.grey-text {
    color: #666666 !important;
}

.bg-pastelblue {
    background-color: #BFD3E6;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}
